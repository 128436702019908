import Vue from 'vue';
import { v4 } from 'uuid';
import TYPES from '@/types';
import { requiredRule } from '@/vue-app/utils/form-rules';

// Application
import CalculateCustomGoalFixedTimeCalculationQuery
  from '@/modules/flagship/custom-goal-calculations/application/querys/calculate-custom-goal-fixed-time-calculation-query';
import UpdateCustomInvestorGoalNameCommand
  from '@/modules/flagship/custom-investor-goal/application/command/update-custom-investor-goal-name-command';
import UpdateInvestorGoalDatesCommand
  from '@/modules/flagship/investor-goal/investor-goal-dates/application/commands/update-investor-goal-dates-command';
import CreateCustomInvestorGoalCommand
  from '@/modules/flagship/custom-investor-goal/application/command/create-custom-investor-goal-command';
import GetCustomInvestorGoalSearchByCustomerQuery
  from '@/modules/flagship/custom-investor-goal/application/query/get-custom-investor-goal-search-by-customer-query';

// Domain
import {
  CustomInvestorGoalDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-dto';
import {
  InvestorGoalDatesDto,
} from '@/modules/flagship/investor-goal/investor-goal-dates/domain/dtos/investor-goal-dates-dto';
import {
  CustomInvestorGoalNameDto,
} from '@/modules/flagship/custom-investor-goal/domain/dtos/custom-investor-goal-name-dto';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AddCustomInvestorGoalWizardAdjustGoalsViewModel {
  @Inject(TYPES.CALCULATE_CUSTOM_GOAL_FIXED_TIME_CALCULATION_QUERY)
  private readonly calculate_custom_goal_fixed_time_query!:
    CalculateCustomGoalFixedTimeCalculationQuery;

  @Inject(TYPES.UPDATE_CUSTOM_INVESTOR_GOAL_NAME_COMMAND)
  private readonly update_custom_goal_command!: UpdateCustomInvestorGoalNameCommand;

  @Inject(TYPES.CREATE_CUSTOM_INVESTOR_GOAL_COMMAND)
  private readonly create_custom_investor_goal_cmd!: CreateCustomInvestorGoalCommand;

  @Inject(TYPES.UPDATE_INVESTOR_GOAL_DATES_COMMAND)
  private readonly update_investor_goal_dates_command!: UpdateInvestorGoalDatesCommand;

  @Inject(TYPES.GET_CUSTOM_INVESTOR_GOAL_SEARCH_BY_CUSTOMER_QUERY)
  private readonly get_search_by_customer_query!: GetCustomInvestorGoalSearchByCustomerQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  readonly i18n_namespace = 'components.goals-dashboard.add-goals.add-custom-investor-goal-wizard-adjust-goals';

  readonly view: Vue;

  slider_max_value= 50000;

  slider_min_value = 100;

  custom_investor_goal_dto: CustomInvestorGoalDto = {
    id: v4(),
    goal_name: '',
    desired_amount: 0,
    fixed_time_adjusted: '',
    investor_goal: {
      investor_profile_id: '',
      initial_amount: 0,
      accumulated_amount: 0,
      monthly_required_amount: 0,
    },
    custom_goal_type: {
      id: '',
      icon_name: '',
      name: '',
      label: '',
      description: '',
      created_at: '',
      updated_at: '',
    },
  }

  private custom_investor_goal_name_dto: CustomInvestorGoalNameDto = {
    id: '',
    goal_name: '',
    accumulated_amount: '',
    monthly_required_amount: '',
    fixed_time_adjusted: '',
    custom_goal_type: {
      id: '',
      icon_name: '',
      name: '',
      label: '',
      description: '',
      updated_at: '',
      created_at: '',
    },
  }

  private investor_goal_dates_dto_custom: InvestorGoalDatesDto = {
    investor_goal_id: '',
    investment_goal_type_id: '',
    period: 0,
  }

  monthly_required_amount_field = 0;

  is_valid_form = false;

  input_rules = {
    monthly_required_amount: [
      requiredRule,
      (value: string) => (
        parseFloat(value.toString().replace(/[^0-9.-]/g, '')) > 0
        || this.translate_errors('utils.form-rules.minimum_error', { value: '$1.00 MXN' })
      ),
    ],
  };

  timer?: NodeJS.Timer;

  is_loading = false;

  is_saving = false;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  translate_errors = (message: string, values?: Values) => this.translator.translate(`${message}`, values);

  initialize = async (custom_investor_goal_dto: CustomInvestorGoalDto) => {
    this.view.$emit('loadingStep', true);
    this.custom_investor_goal_dto = { ...custom_investor_goal_dto };
    // eslint-disable-next-line max-len
    this.monthly_required_amount_field = Math.trunc(Number(custom_investor_goal_dto.investor_goal.monthly_required_amount!.toString()));
    await this.loadCustomInvestorGoal();
    this.view.$emit('loadingStep', false);
  }

  get customized_goal_fixed_time_rounded() {
    return Math.round(Number(this.custom_investor_goal_dto.fixed_time_adjusted));
  }

  get overall_investment_amount_formatted() {
    return String(this.monthly_required_amount_field).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  get is_continue_btn_disabled() {
    return !this.is_valid_form || this.is_loading;
  }

  changeCustomGoalSlider = async () => {
    // eslint-disable-next-line max-len
    this.monthly_required_amount_field = this.custom_investor_goal_dto.investor_goal.monthly_required_amount!;
    await this.calculateCustomGoalFixedTime();
  };

  delay = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      await this.changeCustomGoalField();
    }, 1000);
  }

  changeCustomGoalField = async () => {
    if (this.is_valid_form) {
      const monthly_payment = (String(this.monthly_required_amount_field) !== '$0 MXN')
        ? Number(parseFloat(String(this.monthly_required_amount_field).replaceAll(/[^\d.-]/g, '')).toFixed(2))
        : this.slider_min_value;

      // eslint-disable-next-line max-len
      this.custom_investor_goal_dto.investor_goal.monthly_required_amount = monthly_payment < this.slider_max_value
        ? monthly_payment : this.slider_max_value;

      this.monthly_required_amount_field = monthly_payment < this.slider_max_value
        ? monthly_payment : this.slider_max_value;

      await this.calculateCustomGoalFixedTime();
    }
  };

  calculateCustomGoalFixedTime = async () => {
    try {
      this.is_loading = true;
      const calculation = await this.calculate_custom_goal_fixed_time_query.execute({
        custom_investor_goal_id: this.custom_investor_goal_dto.id,
        // eslint-disable-next-line max-len
        monthly_required_amount: this.custom_investor_goal_dto.investor_goal.monthly_required_amount,
      });

      this.custom_investor_goal_dto.fixed_time_adjusted = String(calculation
        .custom_investor_goal_calculation.fixed_time_adjusted);
      this.custom_investor_goal_name_dto.fixed_time_adjusted = String(calculation
        .custom_investor_goal_calculation.fixed_time_adjusted);

      this.investor_goal_dates_dto_custom.period = calculation
        .custom_investor_goal_calculation.fixed_time_adjusted;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_custom_goal_fixed_time'));
    } finally {
      this.is_loading = false;
    }
  };

  patchCustomInvestorGoal = async () => {
    try {
      this.is_saving = true;
      this.custom_investor_goal_name_dto.id = this.custom_investor_goal_dto.id;
      this.custom_investor_goal_name_dto.goal_name = this.custom_investor_goal_dto.goal_name!;
      // eslint-disable-next-line max-len
      this.custom_investor_goal_name_dto.fixed_time_adjusted = this.custom_investor_goal_dto.fixed_time_adjusted!;
      // eslint-disable-next-line max-len
      this.custom_investor_goal_name_dto.accumulated_amount = this.custom_investor_goal_dto.investor_goal.accumulated_amount!.toString();
      // eslint-disable-next-line max-len
      this.custom_investor_goal_name_dto.monthly_required_amount = this.custom_investor_goal_dto.investor_goal.monthly_required_amount!.toString();
      // eslint-disable-next-line max-len
      this.custom_investor_goal_name_dto.custom_goal_type = this.custom_investor_goal_dto.custom_goal_type!;
      await this.update_custom_goal_command.execute(this.custom_investor_goal_name_dto);
      await this.updateInvestorGoalDates();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_custom_investor_goal'));
    } finally {
      this.is_saving = false;
    }
  }

  loadCustomInvestorGoal = async () => {
    try {
      const custom_investor_goal_a = await this.get_search_by_customer_query.execute(true);

      const custom_investor_goal = custom_investor_goal_a.filter(
        (goal) => goal.id === this.custom_investor_goal_dto.id,
      );

      if (custom_investor_goal.length === 0) {
        await this.createCustomInvestorGoal();
      } else {
        // eslint-disable-next-line max-len
        this.investor_goal_dates_dto_custom.investment_goal_type_id = custom_investor_goal[0].investor_goal.investment_goal_type_id;
        // eslint-disable-next-line max-len
        this.investor_goal_dates_dto_custom.investor_goal_id = custom_investor_goal[0].investor_goal_id;
        this.investor_goal_dates_dto_custom.period = custom_investor_goal[0].fixed_time_adjusted;
        this.slider_max_value = custom_investor_goal[0].desired_amount;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_custom_investor_goal'));
    }
  }

  createCustomInvestorGoal = async () => {
    try {
      this.view.$emit('loadingStep', true);
      this.custom_investor_goal_dto.investor_goal = {
        investor_profile_id: this.custom_investor_goal_dto.investor_goal.investor_profile_id,
        accumulated_amount: this.custom_investor_goal_dto.investor_goal.accumulated_amount,
        // eslint-disable-next-line max-len
        monthly_required_amount: this.custom_investor_goal_dto.investor_goal.monthly_required_amount,
        initial_amount: this.custom_investor_goal_dto.investor_goal.initial_amount,
      };

      await this.create_custom_investor_goal_cmd.execute(this.custom_investor_goal_dto);
      await this.loadCustomInvestorGoal();
    } catch {
      this.message_notifier.showErrorNotification(`${this.translate('errors.create_custom_investor_goal')}`);
    }
  }

  updateInvestorGoalDates = async () => {
    try {
      await this.update_investor_goal_dates_command.execute(this.investor_goal_dates_dto_custom);
      this.view.$emit('creationGoalFlowCompleted');
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.patch_investor_goal_dates_custom'));
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    await this.patchCustomInvestorGoal();
  }
}
